import { ESpotContainerType, useESpotDataFromName } from '@/data/Content/_ESpotDataFromName';
import { processMarketingContent } from '@/utils/processMarketingContent';
import { useMemo } from 'react';
import {
    UNIFIED_404,
} from '@/data/constants/marketing';

const use404ESpotsData = () => {
    const dataMap = (contents?: ESpotContainerType) =>
        contents?.MarketingSpotData?.at(0)?.baseMarketingSpotActivityData?.map(processMarketingContent);
    const { data: _data_unified_page } = useESpotDataFromName(UNIFIED_404);
    const eSpotUnifiedPage = useMemo(() => dataMap(_data_unified_page), [_data_unified_page]);

    return {
        eSpotUnifiedPage,
    };
};

export default use404ESpotsData;
