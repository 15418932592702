/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { ID } from '@/data/types/Basic';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import use404ESpotData from '@/data/Content/404Espots';
import { renderContent } from '@/utils/renderContent';

export const Error404: FC<{ id: ID }> = () => {
	const {
		eSpotUnifiedPage,
	} = use404ESpotData();

	return (
		<Stack textAlign="center" alignContent="center" alignItems="center" alignSelf="center" py={{ 'xs': 3, 'sm': 6 }}>
			{eSpotUnifiedPage?.map((content) => renderContent(content))}
		</Stack>
	);
};
